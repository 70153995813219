import React from "react";
import "./DuroflexBackMagicPro.css";
import { Container, Nav, Navbar, Button, Carousel, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MDBIcon, MDBCol, MDBRow, MDBContainer, MDBFooter } from "mdb-react-ui-kit";
import { BsWhatsapp } from "react-icons/bs";
import { BiSolidPhoneCall } from "react-icons/bi";
import Logo from "../src/photos/Mattress Bazar Logo.png";
import backmagicpro1 from "../src/photos/Mattress/Duroflex/Duropedic/Back Magic Pro/BackMagicPro1.png";
import backmagicpro2 from "../src/photos/Mattress/Duroflex/Duropedic/Back Magic Pro/BackMagicPro2.png";
import backmagicpro3 from "../src/photos/Mattress/Duroflex/Duropedic/Back Magic Pro/BackMagicPro3.png";
import backmagicpro4 from "../src/photos/Mattress/Duroflex/Duropedic/Back Magic Pro/BackMagicPro4.png";
import backmagicpro5 from "../src/photos/Mattress/Duroflex/Duropedic/Back Magic Pro/BackMagicPro5.png";
import backmagicpro6 from "../src/photos/Mattress/Duroflex/Duropedic/Back Magic Pro/BackMagicPro6.png";
import backmagicpro7 from "../src/photos/Mattress/Duroflex/Duropedic/Back Magic Pro/BackMagicPro7.png";
import backmagicpro8 from "../src/photos/Mattress/Duroflex/Duropedic/Back Magic Pro/BackMagicPro8.png";
import newbackmagicpro1 from "../src/photos/Mattress/Duroflex/Duropedic/Back Magic Pro/NewBackMagicPro1.png";
import newbackmagicpro2 from "../src/photos/Mattress/Duroflex/Duropedic/Back Magic Pro/NewBackMagicPro2.png";
import newbackmagicpro3 from "../src/photos/Mattress/Duroflex/Duropedic/Back Magic Pro/NewBackMagicPro3.png";
import newbackmagicpro4 from "../src/photos/Mattress/Duroflex/Duropedic/Back Magic Pro/NewBackMagicPro4.png";
import newbackmagicpro5 from "../src/photos/Mattress/Duroflex/Duropedic/Back Magic Pro/NewBackMagicPro5.png";
import newbackmagicpro6 from "../src/photos/Mattress/Duroflex/Duropedic/Back Magic Pro/NewBackMagicPro6.png";
import newbackmagicpro7 from "../src/photos/Mattress/Duroflex/Duropedic/Back Magic Pro/NewBackMagicPro7.png";

function DuroflexBackMagicPro() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="home_nav" sticky="top">
                <Container>
                    <Navbar.Brand href="/">
                        <Link to="/">
                            <img
                                src={Logo}
                                className="d-inline-block align-top"
                                alt=""
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#features"></Nav.Link>
                            <Nav.Link href="#pricing"></Nav.Link>
                        </Nav>
                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/" className="mx-3 navb_col">HOME</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Mattress" className="mx-3 navb_col">MATTRESS</Nav.Link>
                                {/* <NavDropdown title="MATTRESS" id="collasible-nav-dropdown" className="mx-3">
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#MattressBySize">Mattress By Size</NavDropdown.Item>
                                    </Button>
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#MattressByBrand">Mattress By Brand</NavDropdown.Item>
                                    </Button>
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#BestSellers">Best Sellers</NavDropdown.Item>
                                    </Button>
                                </NavDropdown> */}
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Pillow" className="mx-3 navb_col">PILLOW</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Sofa" className="mx-3 navb_col">SOFA</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/MattressProtector" className="mx-3 navb_col">MATTRESS PROTECTOR</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/ContactUs" className="mx-3 navb_col">CONTACT US</Nav.Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* ---------------Navbar Complete--------------- */}

            <Container fluid>
                {/* <Row>
                    <Col>
                        <p className="text-center backmagicpro_name mt-4">UpRight Mattress Range</p>
                    </Col>
                </Row> */}

                <Row>
                    <Col className="mt-5" lg={6} md={6} sm={12} xs={12}>
                        <Card className="backmagicpro_border">
                            <Row>
                                <Col>
                                    <Carousel fade className="mt-3">
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={newbackmagicpro1}
                                                alt="First slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={newbackmagicpro2}
                                                alt="First slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={newbackmagicpro3}
                                                alt="First slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={newbackmagicpro4}
                                                alt="First slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={newbackmagicpro5}
                                                alt="First slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={newbackmagicpro6}
                                                alt="First slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={newbackmagicpro7}
                                                alt="First slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={backmagicpro1}
                                                alt="First slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={backmagicpro2}
                                                alt="Second slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={backmagicpro3}
                                                alt="Third slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={backmagicpro4}
                                                alt="Fourth slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={backmagicpro5}
                                                alt="Fifth slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={backmagicpro6}
                                                alt="Sixth slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={backmagicpro7}
                                                alt="Seventh slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={backmagicpro8}
                                                alt="Eighth slide"
                                            />
                                        </Carousel.Item>
                                    </Carousel>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col className="mt-5" lg={6} md={6} sm={12} xs={12}>
                        <Card className="backmagicpro_border">
                            <Row className="p-3">
                                <Col>
                                    <p className="backmagicpro_name">Back Magic Pro Mattress Range</p>
                                    <p> •	Back Magic Pro is a Doctor recommended Orthopedic Mattress optimised for better sleep. This Mattress features our Exclusive 5 Zone Support Layer, this mattress gives differ layer of comfort for all zones of body.<br /><br />
                                        •	This mattress is Breathable High Density Bonded Foam at the core which gives durability and stability to the mattress.<br /><br />
                                        •	Its Knitted Soft Fabric is quilted in Soft PU Foam gives body good comfort.<br /><br />
                                        •	This Mattress has an anti-skid Fabric on the bottom to ensure no movement while you sleep<br /><br />
                                        •	Warranty: This Mattress comes with is Assured 5 Years Warranty.<br /><br />
                                        •	Comfort Level: [MEDIUM firm]<br /><br />
                                        •	Thickness: Available in 6”<br /><br />
                                        •	Key Features:<br />  1] Spinal Support ,<br /> 2] doctor recommended ,<br /> 3] Anti Microbial Fabric ,<br /> 4] 5-Zone Support

                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <div class="icon-bar">
                    <a href="https://wa.me/+919619923712"
                        // class="whatsapp_float"
                        target="_blank"
                        rel="noopener noreferrer">
                        <BsWhatsapp fontSize={45} className="whatsappcolor p-2" />
                    </a>

                    <a href="tel:9619923712">
                        <BiSolidPhoneCall className="call p-1" />
                    </a>
                </div>


                {/* ----------FOOTER-------------- */}

                <Row>
                    <Col>
                        <MDBFooter className='text-center text-lg-start text-muted footerbackcolor'>
                            <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>

                                <MDBContainer className='text-center text-md-start mt-5'>
                                    <MDBRow className='mt-3'>
                                        <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>
                                                <MDBIcon icon="gem" />
                                                MATTRESS BAZAR.IN
                                            </h6>
                                            <p>
                                                <Link to="/">
                                                    <img
                                                        src={Logo}
                                                        className="d-inline-block align-top"
                                                        alt=""
                                                    />
                                                </Link>
                                            </p>
                                        </MDBCol>

                                        <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>Products</h6>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    MATTRESS
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Mattress" className="underline">
                                                        MATTRESS
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    PILLOW
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Pillow" className="underline">
                                                        PILLOW
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    SOFA
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Sofa" className="underline">
                                                        SOFA
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    MATTRESS PROTECTOR
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/MattressProtector" className="underline">
                                                        MATTRESS PROTECTOR
                                                    </Link>
                                                </Button>
                                            </p>
                                        </MDBCol>

                                        <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>Useful links</h6>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    CONTACT US
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/ContactUs" className="underline">
                                                        CONTACT US
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    HELP
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="#" className="underline">
                                                        HELP
                                                    </Link>
                                                </Button>
                                            </p>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </section>

                            <div className='text-center p-4 footbottomcolor foottextcolor2'>
                                © 2024 Copyright:
                                <a className='text-reset fw-bold' href='https://mdbootstrap.com/'>
                                    MattressBazar.in
                                </a>
                            </div>
                        </MDBFooter>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default DuroflexBackMagicPro;