import React from "react";
import "./Kurlon.css";
import { Button, Container, Nav, Navbar, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MDBIcon, MDBCol, MDBRow, MDBContainer, MDBFooter } from "mdb-react-ui-kit";
import { BsWhatsapp } from "react-icons/bs";
import { BiSolidPhoneCall } from "react-icons/bi";
import Logo from "../src/photos/Mattress Bazar Logo.png";
import duorest3 from "../src/photos/Mattress/Kurlon/Duo Rest/DuoRest3.png";
import everfirmkurlon1 from "../src/photos/Mattress/Kurlon/Everfirm/Everfirm1.png";
import kurlobond1 from "../src/photos/Mattress/Kurlon/Kurlobond/Kurlobond1.png";
import kurlobondplus1 from "../src/photos/Mattress/Kurlon/Kurlobond Plus/Kurlobond Plus1.png";
import orthomagic1 from "../src/photos/Mattress/Kurlon/Ortho Magic/Ortho Magic1.png";
import purecoir1 from "../src/photos/Mattress/Kurlon/Pure Coir/PureCoir1.png";
import settle1 from "../src/photos/Mattress/Kurlon/Settle/Settle1.png";
import spinecomfort1 from "../src/photos/Mattress/Kurlon/Spine Comfort/Spine Comfort1.png";

function Kurlon() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="home_nav" sticky="top">
                <Container>
                    <Navbar.Brand href="/">
                        <Link to="/">
                            <img
                                src={Logo}
                                className="d-inline-block align-top"
                                alt=""
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#features"></Nav.Link>
                            <Nav.Link href="#pricing"></Nav.Link>
                        </Nav>
                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/" className="mx-3 navb_col">HOME</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Mattress" className="mx-3 navb_col">MATTRESS</Nav.Link>
                                {/* <NavDropdown title="MATTRESS" id="collasible-nav-dropdown" className="mx-3">
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#MattressBySize">Mattress By Size</NavDropdown.Item>
                                    </Button>
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#MattressByBrand">Mattress By Brand</NavDropdown.Item>
                                    </Button>
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#BestSellers">Best Sellers</NavDropdown.Item>
                                    </Button>
                                </NavDropdown> */}
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Pillow" className="mx-3 navb_col">PILLOW</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Sofa" className="mx-3 navb_col">SOFA</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/MattressProtector" className="mx-3 navb_col">MATTRESS PROTECTOR</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/ContactUs" className="mx-3 navb_col">CONTACT US</Nav.Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* ---------------Navbar Complete--------------- */}

            <Container fluid>
                <Row>
                    <Col className="text-center mt-4">
                        <p className="kurlon_textcol1">KurlOn Mattress</p>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-2" lg={3} xs={6}>
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/KurlonDuoRest" className="underline">
                                <Card className="kurlon_size zoom">
                                    <Card.Img variant="top" src={duorest3} />
                                    <Card.Body>
                                        <Card.Title className="text-center mattress_nametext1">Duo Rest Mattress</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-2" lg={3} xs={6}>
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/KurlonEverfirm" className="underline">
                                <Card className="kurlon_size zoom">
                                    <Card.Img variant="top" src={everfirmkurlon1} />
                                    <Card.Body>
                                        <Card.Title className="text-center mattress_nametext1">Everfirm Mattress</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-2" lg={3} xs={6}>
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/KurlonKurlobond" className="underline">
                                <Card className="kurlon_size zoom">
                                    <Card.Img variant="top" src={kurlobond1} />
                                    <Card.Body>
                                        <Card.Title className="text-center mattress_nametext1">Kurlobond Mattress</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-2" lg={3} xs={6}>
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/KurlonKurlobondPlus" className="underline">
                                <Card className="kurlon_size zoom">
                                    <Card.Img variant="top" src={kurlobondplus1} />
                                    <Card.Body>
                                        <Card.Title className="text-center mattress_nametext1">Kurlobond Plus Mattress</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-4" lg={3} xs={6}>
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/KurlonOrthoMagic" className="underline">
                                <Card className="kurlon_size zoom">
                                    <Card.Img variant="top" src={orthomagic1} />
                                    <Card.Body>
                                        <Card.Title className="text-center mattress_nametext1">Ortho Magic Mattress</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-4" lg={3} xs={6}>
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/KurlonPureCoir" className="underline">
                                <Card className="kurlon_size zoom">
                                    <Card.Img variant="top" src={purecoir1} />
                                    <Card.Body>
                                        <Card.Title className="text-center mattress_nametext1">Pure Coir Mattress</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-4" lg={3} xs={6}>
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/KurlonSettle" className="underline">
                                <Card className="kurlon_size zoom">
                                    <Card.Img variant="top" src={settle1} />
                                    <Card.Body>
                                        <Card.Title className="text-center mattress_nametext1">Settle Mattress</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-4" lg={3} xs={6}>
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/KurlonSpineComfort" className="underline">
                                <Card className="kurlon_size zoom">
                                    <Card.Img variant="top" src={spinecomfort1} />
                                    <Card.Body>
                                        <Card.Title className="text-center mattress_nametext1">Spine Comfort Mattress</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>
                </Row>

                <div class="icon-bar">
                    <a href="https://wa.me/+919619923712"
                        // class="whatsapp_float"
                        target="_blank"
                        rel="noopener noreferrer">
                        <BsWhatsapp fontSize={45} className="whatsappcolor p-2" />
                    </a>

                    <a href="tel:9619923712">
                        <BiSolidPhoneCall className="call p-1" />
                    </a>
                </div>


                {/* ----------FOOTER-------------- */}

                <Row>
                    <Col>
                        <MDBFooter className='text-center text-lg-start text-muted footerbackcolor'>
                            <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>

                                <MDBContainer className='text-center text-md-start mt-5'>
                                    <MDBRow className='mt-3'>
                                        <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>
                                                <MDBIcon icon="gem" />
                                                MATTRESS BAZAR.IN
                                            </h6>
                                            <p>
                                                <Link to="/">
                                                    <img
                                                        src={Logo}
                                                        className="d-inline-block align-top"
                                                        alt=""
                                                    />
                                                </Link>
                                            </p>
                                        </MDBCol>

                                        <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>Products</h6>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    MATTRESS
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Mattress" className="underline">
                                                        MATTRESS
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    PILLOW
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Pillow" className="underline">
                                                        PILLOW
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    SOFA
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Sofa" className="underline">
                                                        SOFA
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    MATTRESS PROTECTOR
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/MattressProtector" className="underline">
                                                        MATTRESS PROTECTOR
                                                    </Link>
                                                </Button>
                                            </p>
                                        </MDBCol>

                                        <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>Useful links</h6>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    CONTACT US
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/ContactUs" className="underline">
                                                        CONTACT US
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    HELP
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="#" className="underline">
                                                        HELP
                                                    </Link>
                                                </Button>
                                            </p>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </section>

                            <div className='text-center p-4 footbottomcolor foottextcolor2'>
                                © 2024 Copyright:
                                <a className='text-reset fw-bold' href='https://mdbootstrap.com/'>
                                    MattressBazar.in
                                </a>
                            </div>
                        </MDBFooter>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Kurlon;