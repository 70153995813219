import React from "react";
import "./KurlonDuoRest.css"
import { Button, Container, Nav, Navbar, Carousel, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MDBIcon, MDBCol, MDBRow, MDBContainer, MDBFooter } from "mdb-react-ui-kit";
import { BsWhatsapp } from "react-icons/bs";
import { BiSolidPhoneCall } from "react-icons/bi";
import Logo from "../src/photos/Mattress Bazar Logo.png";
import duorest1 from "../src/photos/Mattress/Kurlon/Duo Rest/Duo Rest1.png";
import duorest2 from "../src/photos/Mattress/Kurlon/Duo Rest/Duo Rest2.jpg";
import duorest3 from "../src/photos/Mattress/Kurlon/Duo Rest/Duo Rest3.jpg";
import duorest4 from "../src/photos/Mattress/Kurlon/Duo Rest/Duo Rest4.jpg";

function KurlonDuoRest() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="home_nav" sticky="top">
                <Container>
                    <Navbar.Brand href="/">
                        <Link to="/">
                            <img
                                src={Logo}
                                className="d-inline-block align-top"
                                alt=""
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#features"></Nav.Link>
                            <Nav.Link href="#pricing"></Nav.Link>
                        </Nav>
                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/" className="mx-3 navb_col">HOME</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Mattress" className="mx-3 navb_col">MATTRESS</Nav.Link>
                                {/* <NavDropdown title="MATTRESS" id="collasible-nav-dropdown" className="mx-3">
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#MattressBySize">Mattress By Size</NavDropdown.Item>
                                    </Button>
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#MattressByBrand">Mattress By Brand</NavDropdown.Item>
                                    </Button>
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#BestSellers">Best Sellers</NavDropdown.Item>
                                    </Button>
                                </NavDropdown> */}
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Pillow" className="mx-3 navb_col">PILLOW</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Sofa" className="mx-3 navb_col">SOFA</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/MattressProtector" className="mx-3 navb_col">MATTRESS PROTECTOR</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/ContactUs" className="mx-3 navb_col">CONTACT US</Nav.Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* ---------------Navbar Complete--------------- */}

            <Container fluid>
                {/* <Row>
                    <Col>
                        <p className="text-center balanceplus_name mt-4">UpRight Mattress Range</p>
                    </Col>
                </Row> */}

                <Row>
                    <Col className="mt-5" lg={6} md={6} sm={12} xs={12}>
                        <Card className="duorest_border">
                            <Row>
                                <Col>
                                    <Carousel fade className="mt-3">
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={duorest1}
                                                alt="First slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={duorest2}
                                                alt="Second slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={duorest3}
                                                alt="Third slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={duorest4}
                                                alt="Fourth slide"
                                            />
                                        </Carousel.Item>
                                    </Carousel>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col className="mt-5" lg={6} md={6} sm={12} xs={12}>
                        <Card className="duorest_border">
                            <Row className="p-3">
                                <Col>
                                    <p className="duorest_name">Duo Rest Mattress Range</p>
                                    <p> DESCRIPTION: <br /><br />
                                        •   Introducing our fantastic foam mattress, packed with amazing features to help you get the best night’s sleep possible. <br /><br />
                                        •   The HR foam layers at the top are designed to adjust and reshape according to your body’s unique shape and weight distribution, providing a customised and comfortable sleeping experience. <br /><br />
                                        •   Our mattress also features a breathable bonded foam core that gives your body the essential support it needs to rest comfortably, along with high GSM knitted fabric quilting on top that feels gentle on the skin. <br /><br />
                                        •   And with a medium-firm comfort level, you can trust that this foam mattress will provide you with the ultimate sleeping experience for years to come. <br /><br />

                                        FEATURES: <br /><br />
                                        •   H R Soft Foam <br /><br />
                                        •   High Density Orthopedic Foam <br /><br />
                                        •   P U Foam Quilt Bottom <br /><br />
                                        •   Comfort Feel : Medium Soft <br /><br />

                                        •   Warranty : 4 Years <br /><br />
                                        •   Free Shipping in Mumbai <br /><br />

                                        •   Thickness: 5” Inches <br /><br />

                                        •   Sizes - Single , Double , Queen , King <br />
                                        ( Also available in Custom Size)

                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <div class="icon-bar">
                    <a href="https://wa.me/+919619923712"
                        // class="whatsapp_float"
                        target="_blank"
                        rel="noopener noreferrer">
                        <BsWhatsapp fontSize={45} className="whatsappcolor p-2" />
                    </a>

                    <a href="tel:9619923712">
                        <BiSolidPhoneCall className="call p-1" />
                    </a>
                </div>

                {/* ----------FOOTER-------------- */}

                <Row>
                    <Col>
                        <MDBFooter className='text-center text-lg-start text-muted footerbackcolor'>
                            <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>

                                <MDBContainer className='text-center text-md-start mt-5'>
                                    <MDBRow className='mt-3'>
                                        <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>
                                                <MDBIcon icon="gem" />
                                                MATTRESS BAZAR.IN
                                            </h6>
                                            <p>
                                                <Link to="/">
                                                    <img
                                                        src={Logo}
                                                        className="d-inline-block align-top"
                                                        alt=""
                                                    />
                                                </Link>
                                            </p>
                                        </MDBCol>

                                        <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>Products</h6>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    MATTRESS
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Mattress" className="underline">
                                                        MATTRESS
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    PILLOW
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Pillow" className="underline">
                                                        PILLOW
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    SOFA
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Sofa" className="underline">
                                                        SOFA
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    MATTRESS PROTECTOR
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/MattressProtector" className="underline">
                                                        MATTRESS PROTECTOR
                                                    </Link>
                                                </Button>
                                            </p>
                                        </MDBCol>

                                        <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>Useful links</h6>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    CONTACT US
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/ContactUs" className="underline">
                                                        CONTACT US
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    HELP
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="#" className="underline">
                                                        HELP
                                                    </Link>
                                                </Button>
                                            </p>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </section>

                            <div className='text-center p-4 footbottomcolor foottextcolor2'>
                                © 2024 Copyright:
                                <a className='text-reset fw-bold' href='https://mdbootstrap.com/'>
                                    MattressBazar.in
                                </a>
                            </div>
                        </MDBFooter>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default KurlonDuoRest;