import React from "react";
import "./ContactUs.css";
import { Container, Navbar, Nav, Button, Row, Col, Card, CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MDBIcon, MDBCol, MDBRow, MDBContainer, MDBFooter } from "mdb-react-ui-kit";
import Logo from "../src/photos/Mattress Bazar Logo.png";
import { BiSolidPhoneCall } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";
import kurlonshop from "../src/photos/KurlOn Shop.png";
import sleepwellshop from "../src/photos/Sleepwell Shop.png";
import duroflexshop from "../src/photos/Duroflex Shop.png";

function ContactUs() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="home_nav" sticky="top">
                <Container>
                    <Navbar.Brand href="/">
                        <Link to="/">
                            <img
                                src={Logo}
                                className="d-inline-block align-top"
                                alt=""
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#features"></Nav.Link>
                            <Nav.Link href="#pricing"></Nav.Link>
                        </Nav>
                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/" className="mx-3 navb_col">HOME</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Mattress" className="mx-3 navb_col">MATTRESS</Nav.Link>
                                {/* <NavDropdown title="MATTRESS" id="collasible-nav-dropdown" className="mx-3">
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#MattressBySize">Mattress By Size</NavDropdown.Item>
                                    </Button>
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#MattressByBrand">Mattress By Brand</NavDropdown.Item>
                                    </Button>
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#BestSellers">Best Sellers</NavDropdown.Item>
                                    </Button>
                                </NavDropdown> */}
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Pillow" className="mx-3 navb_col">PILLOW</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Sofa" className="mx-3 navb_col">SOFA</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/MattressProtector" className="mx-3 navb_col">MATTRESS PROTECTOR</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/ContactUs" className="mx-3 navb_col">CONTACT US</Nav.Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* ---------------Navbar Complete--------------- */}

            <Container fluid>
                <Row>
                    <Col className="text-center mt-3">
                        <p className="contact_textcol">CONTACT US</p>
                    </Col>
                </Row>

                {/* <Row>
                <Col>
                    <Card>
                        <div className="google-map-code">
                            <iframe title="myFrame" className="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15088.730305572732!2d72.8437474!3d19.0116748!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cfc9f4be13f7%3A0xfd212012191d4fa3!2sDuroflex%20-%20Mattress%2C%20Dadar%20East!5e0!3m2!1sen!2sin!4v1693732119003!5m2!1sen!2sin"
                                width="290"
                                height="500"
                                style={{ border: 0 }}
                                allowfullscreen=""
                                aria-hidden="false"
                                referrerpolicy="no-referrer-when-downgrade">
                            </iframe>
                        </div>
                    </Card>
                </Col>

                <Col>
                    <Card></Card>
                </Col>
            </Row> */}

                <Row>
                    <Col className="mt-4 mb-5">
                        <CardGroup>
                            <Card>
                                <Card.Img variant="top" src={duroflexshop} />
                                <Card.Body>
                                    <Card.Title>DUROFLEX MATTRESS STORE</Card.Title>
                                    <Card.Text>
                                        Shop no 3, Latif Building,
                                        Dr Baba Saheb Ambedkar Rd,
                                        opp. Chitra Cinema,
                                        Dadar East,
                                        Mumbai - 400014
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <Card.Link href="https://maps.app.goo.gl/ReUBEcdNTz8auh1o9?g_st=iw" className="storecol">Press Me to Locate The STORE</Card.Link>
                                </Card.Footer>
                            </Card>
                            <Card>
                                <Card.Img variant="top" src={sleepwellshop} />
                                <Card.Body>
                                    <Card.Title>SLEEPWELL MATTRESS STORE</Card.Title>
                                    <Card.Text>
                                        Shop No 76/A, Kawali Wadi
                                        S.K Bole Road
                                        Near Portuguese Church
                                        Dadar West,
                                        Mumbai - 28
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <Card.Link href="https://goo.gl/maps/gou3fa5eVER2" className="storecol">Press Me to Locate The STORE</Card.Link>
                                </Card.Footer>
                            </Card>
                            <Card>
                                <Card.Img variant="top" src={kurlonshop} />
                                <Card.Body>
                                    <Card.Title>KURLON MATTRESS STORE</Card.Title>
                                    <Card.Text>
                                        Shop No 76,Kawali Wadi
                                        S.K Bole Road
                                        Near Portuguese Church
                                        Dadar West,
                                        Mumbai - 28
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <Card.Link href="https://maps.app.goo.gl/1KekryFkwhs9nL8Y7?g_st=ic" className="storecol">Press Me to Locate The STORE</Card.Link>
                                </Card.Footer>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>

                <div class="icon-bar">
                    <a href="https://wa.me/+919619923712"
                        // class="whatsapp_float"
                        target="_blank"
                        rel="noopener noreferrer">
                        <BsWhatsapp fontSize={45} className="whatsappcolor p-2" />
                    </a>

                    <a href="tel:9619923712">
                        <BiSolidPhoneCall className="call p-1" />
                    </a>
                </div>

                {/* ----------FOOTER-------------- */}

                <Row>
                    <Col>
                        <MDBFooter className='text-center text-lg-start text-muted footerbackcolor'>
                            <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>

                                <MDBContainer className='text-center text-md-start mt-5'>
                                    <MDBRow className='mt-3'>
                                        <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>
                                                <MDBIcon icon="gem" />
                                                MATTRESS BAZAR.IN
                                            </h6>
                                            <p>
                                                <Link to="/">
                                                    <img
                                                        src={Logo}
                                                        className="d-inline-block align-top"
                                                        alt=""
                                                    />
                                                </Link>
                                            </p>
                                        </MDBCol>

                                        <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>Products</h6>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    MATTRESS
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Mattress" className="underline">
                                                        MATTRESS
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    PILLOW
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Pillow" className="underline">
                                                        PILLOW
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    SOFA
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Sofa" className="underline">
                                                        SOFA
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    MATTRESS PROTECTOR
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/MattressProtector" className="underline">
                                                        MATTRESS PROTECTOR
                                                    </Link>
                                                </Button>
                                            </p>
                                        </MDBCol>

                                        <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>Useful links</h6>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    CONTACT US
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/ContactUs" className="underline">
                                                        CONTACT US
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    HELP
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="#" className="underline">
                                                        HELP
                                                    </Link>
                                                </Button>
                                            </p>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </section>

                            <div className='text-center p-4 footbottomcolor foottextcolor2'>
                                © 2024 Copyright:
                                <a className='text-reset fw-bold' href='https://mdbootstrap.com/'>
                                    MattressBazar.in
                                </a>
                            </div>
                        </MDBFooter>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ContactUs;