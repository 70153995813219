import React from "react";
import "./SleepwellFitRestClassic.css";
import { Container, Nav, Navbar, Button, Row, Col, Card, Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MDBIcon, MDBCol, MDBRow, MDBContainer, MDBFooter } from "mdb-react-ui-kit";
import { BsWhatsapp } from "react-icons/bs";
import { BiSolidPhoneCall } from "react-icons/bi";
import Logo from "../src/photos/Mattress Bazar Logo.png";
import fitrestclassic1 from "../src/photos/Mattress/Sleepwell/Fitrest classic/Fitrest Classic1.png";
import fitrestclassic2 from "../src/photos/Mattress/Sleepwell/Fitrest classic/Fitrest Classic2.png";
import fitrestclassic3 from "../src/photos/Mattress/Sleepwell/Fitrest classic/Fitrest Classic3.png";
import fitrestclassic4 from "../src/photos/Mattress/Sleepwell/Fitrest classic/Fitrest Classic4.png";

function SleepwellFitRestClassic() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="home_nav" sticky="top">
                <Container>
                    <Navbar.Brand href="/">
                        <Link to="/">
                            <img
                                src={Logo}
                                className="d-inline-block align-top"
                                alt=""
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#features"></Nav.Link>
                            <Nav.Link href="#pricing"></Nav.Link>
                        </Nav>
                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/" className="mx-3 navb_col">HOME</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Mattress" className="mx-3 navb_col">MATTRESS</Nav.Link>
                                {/* <NavDropdown title="MATTRESS" id="collasible-nav-dropdown" className="mx-3">
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#MattressBySize">Mattress By Size</NavDropdown.Item>
                                    </Button>
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#MattressByBrand">Mattress By Brand</NavDropdown.Item>
                                    </Button>
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#BestSellers">Best Sellers</NavDropdown.Item>
                                    </Button>
                                </NavDropdown> */}
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Pillow" className="mx-3 navb_col">PILLOW</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Sofa" className="mx-3 navb_col">SOFA</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/MattressProtector" className="mx-3 navb_col">MATTRESS PROTECTOR</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/ContactUs" className="mx-3 navb_col">CONTACT US</Nav.Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* ---------------Navbar Complete--------------- */}

            <Container fluid>
                {/* <Row>
                    <Col>
                        <p className="text-center balanceplus_name mt-4">UpRight Mattress Range</p>
                    </Col>
                </Row> */}

                <Row>
                    <Col className="mt-5" lg={6} md={6} sm={12} xs={12}>
                        <Card className="fitrestclassic_border">
                            <Row>
                                <Col>
                                    <Carousel fade className="mt-3">
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={fitrestclassic1}
                                                alt="First slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={fitrestclassic2}
                                                alt="Second slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={fitrestclassic3}
                                                alt="Third slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item interval={2000}>
                                            <img
                                                className="d-block w-75"
                                                src={fitrestclassic4}
                                                alt="Fourth slide"
                                            />
                                        </Carousel.Item>
                                    </Carousel>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col className="mt-5" lg={6} md={6} sm={12} xs={12}>
                        <Card className="fitrestclassic_border">
                            <Row className="p-3">
                                <Col>
                                    <p className="fitrestclassic_name">FitRest Classic Mattress Range</p>
                                    <p> •   DESCRIPTION:<br /><br />
                                        •   The Sleepwell Mattress Model Name Fitrest Classic is a foam-based mattress that boasts our innovative AccuProfile Technology. <br /><br />
                                        •   This technology is designed to provide exceptional back support, making it an ideal choice for individuals who prioritize spinal alignment and comfort during sleep. <br /><br />
                                        •   The unique build of the Fitrest Classic allows for optimal air circulation, ensuring that you stay cool and comfortable throughout the night. <br /><br />
                                        •   The foam construction also provides excellent pressure relief, reducing the risk of discomfort and promoting a more restful sleep experience.  <br /><br />
                                        •   Try it out today and experience the difference that AccuProfile Technology can make in your sleep!<br /><br />
                                        •   FEATURES: <br /><br />
                                        •   Premium Knitted Fabric <br /><br />
                                        •   Double Layered Sleepwell QUILTEC Quilting <br /><br />
                                        •   Soft PU ACCU-Profile Foam <br /><br />
                                        •   ACCU-PROFILE Support Foam <br /><br />
                                        •   Feel: Medium <br /><br />
                                        •   Warranty - 25 Years <br /><br />
                                        •   Thickness: 5” & 6” inches <br /><br />
                                        •   Available Sizes: Single , Double, Queen, King - Also available in custom sizes
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <div class="icon-bar">
                    <a href="https://wa.me/+919619923712"
                        // class="whatsapp_float"
                        target="_blank"
                        rel="noopener noreferrer">
                        <BsWhatsapp fontSize={45} className="whatsappcolor p-2" />
                    </a>

                    <a href="tel:9619923712">
                        <BiSolidPhoneCall className="call p-1" />
                    </a>
                </div>

                {/* ----------FOOTER-------------- */}

                <Row>
                    <Col>
                        <MDBFooter className='text-center text-lg-start text-muted footerbackcolor'>
                            <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>

                                <MDBContainer className='text-center text-md-start mt-5'>
                                    <MDBRow className='mt-3'>
                                        <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>
                                                <MDBIcon icon="gem" />
                                                MATTRESS BAZAR.IN
                                            </h6>
                                            <p>
                                                <Link to="/">
                                                    <img
                                                        src={Logo}
                                                        className="d-inline-block align-top"
                                                        alt=""
                                                    />
                                                </Link>
                                            </p>
                                        </MDBCol>

                                        <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>Products</h6>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    MATTRESS
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Mattress" className="underline">
                                                        MATTRESS
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    PILLOW
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Pillow" className="underline">
                                                        PILLOW
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    SOFA
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Sofa" className="underline">
                                                        SOFA
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    MATTRESS PROTECTOR
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/MattressProtector" className="underline">
                                                        MATTRESS PROTECTOR
                                                    </Link>
                                                </Button>
                                            </p>
                                        </MDBCol>

                                        <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>Useful links</h6>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    CONTACT US
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/ContactUs" className="underline">
                                                        CONTACT US
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    HELP
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="#" className="underline">
                                                        HELP
                                                    </Link>
                                                </Button>
                                            </p>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </section>

                            <div className='text-center p-4 footbottomcolor foottextcolor2'>
                                © 2024 Copyright:
                                <a className='text-reset fw-bold' href='https://mdbootstrap.com/'>
                                    MattressBazar.in
                                </a>
                            </div>
                        </MDBFooter>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SleepwellFitRestClassic;