import React from "react";
import { Navbar, Container, Nav, Button, Row, Col, Carousel, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MDBIcon, MDBFooter, MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import "./Home.css";
import { BsWhatsapp } from "react-icons/bs";
import { BiSolidPhoneCall } from "react-icons/bi";
import Kurlon from "../src/photos/KurlOn Logo.jpeg";
import Sleepwell from "../src/photos/Sleepwell Logo.png";
import Duroflex from "../src/photos/Duroflex Logo.png";
import Singlebedmattress from "../src/photos/Single Bed Mattress.png";
import Doublebedmattress from "../src/photos/Double Bed Mattress.png";
import Kingsizemattress from "../src/photos/King Size Mattress.png";
import Queensizemattress from "../src/photos/Queen Size Mattress.png";
import Mattress from "../src/photos/Mattress.png";
import Sofa from "../src/photos/Sofa.png";
import Pillow from "../src/photos/Pillow.png";
import Bedsheet from "../src/photos/Bedsheet.png";
import MattressProtector from "../src/photos/Mattress Protector.png";
import Logo from "../src/photos/Mattress Bazar Logo.png";
import carousel1 from "../src/photos/Desktop Carousel/1.png";
import carousel2 from "../src/photos/Desktop Carousel/2.png";
import carousel3 from "../src/photos/Desktop Carousel/3.png";
import carousel4 from "../src/photos/Desktop Carousel/4.png";
import carousel5 from "../src/photos/Desktop Carousel/5.png";
import slider1 from "../src/photos/Mobile Carousel/1.png";
import slider2 from "../src/photos/Mobile Carousel/2.png";
import slider3 from "../src/photos/Mobile Carousel/3.png";
import slider4 from "../src/photos/Mobile Carousel/4.png";
import slider5 from "../src/photos/Mobile Carousel/5.png";

function Home() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="home_nav" sticky="top">
                <Container>
                    <Navbar.Brand href="/">
                        <Link to="/">
                            <img
                                src={Logo}
                                className="d-inline-block align-top"
                                alt=""
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#features"></Nav.Link>
                            <Nav.Link href="#pricing"></Nav.Link>
                        </Nav>
                        <Nav>
                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/" className="mx-3 navb_col">HOME</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Mattress" className="mx-3 navb_col">MATTRESS</Nav.Link>
                                {/* <NavDropdown title="MATTRESS" id="collasible-nav-dropdown" className="mx-3">
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#MattressBySize">Mattress By Size</NavDropdown.Item>
                                    </Button>
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#MattressByBrand">Mattress By Brand</NavDropdown.Item>
                                    </Button>
                                    <Button onClick={scrollToTop} className="scrollbtn p-0">
                                        <NavDropdown.Item as={Link} to="#BestSellers">Best Sellers</NavDropdown.Item>
                                    </Button>
                                </NavDropdown> */}
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Pillow" className="mx-3 navb_col">PILLOW</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/Sofa" className="mx-3 navb_col">SOFA</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/MattressProtector" className="mx-3 navb_col">MATTRESS PROTECTOR</Nav.Link>
                            </Button>

                            <Button onClick={scrollToTop} className="scrollbtn p-0">
                                <Nav.Link as={Link} to="/ContactUs" className="mx-3 navb_col">CONTACT US</Nav.Link>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* ---------------Navbar Complete--------------- */}

            <Container fluid>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <Carousel fade className="mt-3 home_slider desktopview w-100">
                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100"
                                    src={carousel1}
                                    alt="Slide1"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={4000}>
                                <Link to="#">
                                    <img
                                        className="d-block w-100"
                                        src={carousel2}
                                        alt="Slide2"
                                    />
                                </Link>
                            </Carousel.Item>
                            <Carousel.Item interval={4000}>
                                <Link to="#">
                                    <img
                                        className="d-block w-100"
                                        src={carousel3}
                                        alt="Slide3"
                                    />
                                </Link>
                            </Carousel.Item>
                            <Carousel.Item interval={4000}>
                                <Link to="#">
                                    <img
                                        className="d-block w-100"
                                        src={carousel4}
                                        alt="Slide4"
                                    />
                                </Link>
                            </Carousel.Item>
                            <Carousel.Item interval={4000}>
                                <Link to="#">
                                    <img
                                        className="d-block w-100"
                                        src={carousel5}
                                        alt="Slide5"
                                    />
                                </Link>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>

                {/* --------------For Desktop View Complete-------------- */}

                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <Carousel fade className="mt-3 home_slider mobileview w-100">
                            <Carousel.Item interval={4000}>
                                <img
                                    className="d-block w-100"
                                    src={slider1}
                                    alt="Slide1"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={4000}>
                                <Link to="#">
                                    <img
                                        className="d-block w-100"
                                        src={slider2}
                                        alt="Slide2"
                                    />
                                </Link>
                            </Carousel.Item>
                            <Carousel.Item interval={4000}>
                                <Link to="#">
                                    <img
                                        className="d-block w-100"
                                        src={slider3}
                                        alt="Slide3"
                                    />
                                </Link>
                            </Carousel.Item>
                            <Carousel.Item interval={4000}>
                                <Link to="#">
                                    <img
                                        className="d-block w-100"
                                        src={slider4}
                                        alt="Slide4"
                                    />
                                </Link>
                            </Carousel.Item>
                            <Carousel.Item interval={4000}>
                                <Link to="#">
                                    <img
                                        className="d-block w-100"
                                        src={slider5}
                                        alt="Slide5"
                                    />
                                </Link>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>

                {/* --------------For Mobile View Complete-------------- */}

                <Row>
                    <Col className="mt-3">
                        <p className="text-center home_textcol">COMPLETE SLEEPING SOLUTION</p>
                    </Col>
                </Row>

                <Row className="text-center">
                    <Col className="mt-3">
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/Mattress" className="underline">
                                <Card className="home_product zoom">
                                    <Card.Img variant="top" src={Mattress} />
                                    <Card.Body>
                                        <Card.Title className="text-center home_matcol">Mattress</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-3">
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/Sofa" className="underline">
                                <Card className="home_product zoom">
                                    <Card.Img variant="top" src={Sofa} />
                                    <Card.Body>
                                        <Card.Title className="text-center home_sofacol">Sofa</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-3">
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/Pillow" className="underline">
                                <Card className="home_product zoom">
                                    <Card.Img variant="top" src={Pillow} />
                                    <Card.Body>
                                        <Card.Title className="text-center home_pillowcol">Pillow</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-3">
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="#" className="underline">
                                <Card className="home_product zoom">
                                    <Card.Img variant="top" src={Bedsheet} />
                                    <Card.Body>
                                        <Card.Title className="text-center home_bedsheetcol">Bedsheet</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-3">
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/MattressProtector" className="underline">
                                <Card className="home_product zoom">
                                    <Card.Img variant="top" src={MattressProtector} />
                                    <Card.Body>
                                        <Card.Title className="text-center home_matprocol">Mattress Protector</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-3">
                        <p className="text-center home_matbysize">MATTRESS BY COMFORT</p>
                    </Col>
                </Row>

                <Row className="text-center">
                    <Col className="mt-3">
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/HardFeelMattress" className="underline">
                                <Card className="home_sizemat zoom">
                                    <Card.Img variant="top" src={Singlebedmattress} />
                                    <Card.Body>
                                        <Card.Title className="text-center home_singlecol">Hard Feel Mattress</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-3">
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/MediumFeelMattress" className="underline">
                                <Card className="home_sizemat zoom">
                                    <Card.Img variant="top" src={Doublebedmattress} />
                                    <Card.Body>
                                        <Card.Title className="text-center home_doublecol">Medium Firm Mattress</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-3">
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/MediumSoftFeelMattress" className="underline">
                                <Card className="home_sizemat zoom">
                                    <Card.Img variant="top" src={Kingsizemattress} />
                                    <Card.Body>
                                        <Card.Title className="text-center home_kingcol">Medium Soft Feel Mattress</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-3">
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/SoftBouncyMattress" className="underline">
                                <Card className="home_sizemat zoom">
                                    <Card.Img variant="top" src={Queensizemattress} />
                                    <Card.Body>
                                        <Card.Title className="text-center home_queencol">Soft / Bouncy Feel Mattress</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-3">
                        <p className="text-center home_matbybrand">MATTRESS BY BRAND</p>
                    </Col>
                </Row>

                <Row className="text-center">
                    <Col className="mt-3">
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/Kurlon" className="underline">
                                <Card className="home_brand zoom">
                                    <Card.Img variant="top" src={Kurlon} />
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-3">
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/Sleepwell" className="underline">
                                <Card className="home_brand zoom">
                                    <Card.Img variant="top" src={Sleepwell} />
                                </Card>
                            </Link>
                        </Button>
                    </Col>

                    <Col className="mt-3">
                        <Button onClick={scrollToTop} className="scrollbt colo p-0">
                            <Link to="/Duroflex" className="underline">
                                <Card className="home_brand zoom">
                                    <Card.Img variant="top" src={Duroflex} className="mt-1" />
                                </Card>
                            </Link>
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-3">
                        <p className="text-center home_review">What Our Customer Say</p>
                    </Col>
                </Row>

                <Row>
                    <Col className="mt-3 mb-3">
                        <Card className="home_review">
                            <iframe src='https://widgets.sociablekit.com/google-reviews/iframe/220001' frameborder='0' width='100%' height='400'></iframe>
                        </Card>
                    </Col>
                </Row>

                <div class="icon-bar">
                    <a href="https://wa.me/+919619923712"
                        // class="whatsapp_float"
                        target="_blank"
                        rel="noopener noreferrer">
                        <BsWhatsapp fontSize={45} className="whatsappcolor p-2" />
                    </a>

                    <a href="tel:9619923712">
                        <BiSolidPhoneCall className="call p-1" />
                    </a>
                </div>

                {/* ----------FOOTER-------------- */}

                <Row>
                    <Col>
                        <MDBFooter className='text-center text-lg-start text-muted footerbackcolor'>
                            <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>

                                <MDBContainer className='text-center text-md-start mt-5'>
                                    <MDBRow className='mt-3'>
                                        <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>
                                                <MDBIcon icon="gem" />
                                                MATTRESS BAZAR.IN
                                            </h6>
                                            <p>
                                                <Link to="/">
                                                    <img
                                                        src={Logo}
                                                        className="d-inline-block align-top"
                                                        alt=""
                                                    />
                                                </Link>
                                            </p>
                                        </MDBCol>

                                        <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>Products</h6>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    MATTRESS
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Mattress" className="underline">
                                                        MATTRESS
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    PILLOW
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Pillow" className="underline">
                                                        PILLOW
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    SOFA
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/Sofa" className="underline">
                                                        SOFA
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    MATTRESS PROTECTOR
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/MattressProtector" className="underline">
                                                        MATTRESS PROTECTOR
                                                    </Link>
                                                </Button>
                                            </p>
                                        </MDBCol>

                                        <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
                                            <h6 className='text-uppercase fw-bold mb-4 foottextcolor'>Useful links</h6>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    CONTACT US
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="/ContactUs" className="underline">
                                                        CONTACT US
                                                    </Link>
                                                </Button>
                                            </p>
                                            <p className="foottextcolor">
                                                {/* <a href='#!' className='text-reset'>
                                                    HELP
                                                </a> */}
                                                <Button onClick={scrollToTop} className="scrollbt bgco colo p-0">
                                                    <Link to="#" className="underline">
                                                        HELP
                                                    </Link>
                                                </Button>
                                            </p>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </section>

                            <div className='text-center p-4 footbottomcolor foottextcolor2'>
                                © 2024 Copyright:
                                <a className='text-reset fw-bold' href='https://mdbootstrap.com/'>
                                    MattressBazar.in
                                </a>
                            </div>
                        </MDBFooter>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Home;