import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './Home';
import Mattress from './Mattress';
import Pillow from './Pillow';
import Sofa from './Sofa';
import ContactUs from './ContactUs';
import Sofawithlounger from './Sofawithlounger';
import Sofacumbedlounger from './Sofacumbedlounger';
import Sofacumbedwooden from './Sofacumbedwooden';
import MattressProtector from './MattressProtector';
import Kurlon from './Kurlon';
import Sleepwell from './Sleepwell';
import Duroflex from './Duroflex';
import DuroflexBackMagicPro from './DuroflexBackMagicPro';
import DuroflexBalance from './DuroflexBalance';
import DuroflexBalancePlus from './DuroflexBalancePlus';
import DuroflexPosturePerfect from './DuroflexPosturePerfect';
import DuroflexSpineRest from './DuroflexSpineRest';
import DuroflexUpright from './DuroflexUpright';
import DuroflexBoltt from './DuroflexBoltt';
import DuroflexBolttPlus from './DuroflexBolttPlus';
import DuroflexPropel from './DuroflexPropel';
import DuroflexPropelPlus from './DuroflexPropelPlus';
import DuroflexQuboid from './DuroflexQuboid';
import DuroflexElement from './DuroflexElement';
import DuroflexNewEdge from './DuroflexNewEdge';
import DuroflexPowerUp from './DuroflexPowerUp';
import DuroflexKaya from './DuroflexKaya';
import DuroflexPrana from './DuroflexPrana';
import DuroflexTatva from './DuroflexTatva';
import SleepwellFitRestClassic from './SleepwellFitRestClassic';
import SleepwellFitRestLuxury from './SleepwellFitRestLuxury';
import SleepwellFitRestPremium from './SleepwellFitRestPremium';
import SleepwellNexaClassic from './SleepwellNexaClassic';
import SleepwellNexaLuxury from './SleepwellNexaLuxury';
import SleepwellNexaPremium from './SleepwellNexaPremium';
import KurlonDuoRest from './KurlonDuoRest';
import KurlonEverfirm from './KurlonEverfirm';
import KurlonKurlobond from './KurlonKurlobond';
import KurlonKurlobondPlus from './KurlonKurlobondPlus';
import KurlonOrthoMagic from './KurlonOrthoMagic';
import KurlonPureCoir from './KurlonPureCoir';
import KurlonSettle from './KurlonSettle';
import KurlonSpineComfort from './KurlonSpineComfort';
import FoamMattress from './FoamMattress';
import SpringMattress from './SpringMattress';
import NaturalCoirMattress from './NaturalCoirMattress';
import OrthoPedicMattress from './OrthoPedicMattress';
import HardFeelMattress from './HardFeelMattress';
import MediumFirmFeelMattress from './MediumFirmFeelMattress';
import MediumFeelMattress from './MediumFeelMattress';
import MediumSoftFeelMattress from './MediumSoftFeelMattress';
import SoftBouncyMattress from './SoftBouncyMattress';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Mattress' element={<Mattress />} />
          <Route path='/Pillow' element={<Pillow />} />
          <Route path='/Sofa' element={<Sofa />} />
          <Route path='/ContactUs' element={<ContactUs />} />
          <Route path='/Sofawithlounger' element={<Sofawithlounger />} />
          <Route path='/Sofacumbedlounger' element={<Sofacumbedlounger />} />
          <Route path='/Sofacumbedwooden' element={<Sofacumbedwooden />} />
          <Route path='/MattressProtector' element={<MattressProtector />} />
          <Route path='/Kurlon' element={<Kurlon />} />
          <Route path='Sleepwell' element={<Sleepwell />} />
          <Route path='/Duroflex' element={<Duroflex />} />
          <Route path='/DuroflexBackMagicPro' element={<DuroflexBackMagicPro />} />
          <Route path='/DuroflexBalance' element={<DuroflexBalance />} />
          <Route path='/DuroflexBalancePlus' element={<DuroflexBalancePlus />} />
          <Route path='/DuroflexPosturePerfect' element={<DuroflexPosturePerfect />} />
          <Route path='/DuroflexSpineRest' element={<DuroflexSpineRest />} />
          <Route path='/DuroflexUpright' element={<DuroflexUpright />} />
          <Route path='/DuroflexBoltt' element={<DuroflexBoltt />} />
          <Route path='/DuroflexBolttPlus' element={<DuroflexBolttPlus />} />
          <Route path='/DuroflexPropel' element={<DuroflexPropel />} />
          <Route path='/DuroflexPropelPlus' element={<DuroflexPropelPlus />} />
          <Route path='/DuroflexQuboid' element={<DuroflexQuboid />} />
          <Route path='/Duroflexelement' element={<DuroflexElement />} />
          <Route path='/DuroflexNewEdge' element={<DuroflexNewEdge />} />
          <Route path='/DuroflexPowerUp' element={<DuroflexPowerUp />} />
          <Route path='/DuroflexKaya' element={<DuroflexKaya />} />
          <Route path='/DuroflexPrana' element={<DuroflexPrana />} />
          <Route path='/DuroflexTatva' element={<DuroflexTatva />} />
          <Route path='/SleepwellFitRestClassic' element={<SleepwellFitRestClassic />} />
          <Route path='/SleepwellFitRestLuxury' element={<SleepwellFitRestLuxury />} />
          <Route path='/SleepwellFitRestPremium' element={<SleepwellFitRestPremium />} />
          <Route path='/SleepwellNexaClassic' element={<SleepwellNexaClassic />} />
          <Route path='/SleepwellNexaLuxury' element={<SleepwellNexaLuxury />} />
          <Route path='/SleepwellNexaPremium' element={<SleepwellNexaPremium />} />
          <Route path='/KurlonDuoRest' element={<KurlonDuoRest />} />
          <Route path='/KurlonEverfirm' element={<KurlonEverfirm />} />
          <Route path='/KurlonKurlobond' element={<KurlonKurlobond />} />
          <Route path='/KurlonKurlobondPlus' element={<KurlonKurlobondPlus />} />
          <Route path='/KurlonOrthoMagic' element={<KurlonOrthoMagic />} />
          <Route path='/KurlonPureCoir' element={<KurlonPureCoir />} />
          <Route path='KurlonSettle' element={<KurlonSettle />} />
          <Route path='/KurlonSpineComfort' element={<KurlonSpineComfort />} />
          <Route path='/FoamMattress' element={<FoamMattress />} />
          <Route path='/SpringMattress' element={<SpringMattress />} />
          <Route path='/NaturalCoirMattress' element={<NaturalCoirMattress />} />
          <Route path='/OrthoPedicMattress' element={<OrthoPedicMattress />} />
          <Route path='/HardFeelMattress' element={<HardFeelMattress />} />
          <Route path='/MediumFirmFeelMattress' element={<MediumFirmFeelMattress />} />
          <Route path='/MediumFeelMattress' element={<MediumFeelMattress />} />
          <Route path='/MediumSoftFeelMattress' element={<MediumSoftFeelMattress />} />
          <Route path='/SoftBouncyMattress' element={<SoftBouncyMattress />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
